import {AppHeaderEN} from "../components/headerEN";
import {AppHeader} from "../components/header";
import React from "react";


export const telegram = (id:string, utm_source:string, language:string) =>{
    let urlT = 'https://t.me/cgc_vetbot';
    let parseSpace = false;
    if (utm_source != '' || id !=''){
        urlT = urlT + '?start=';
        if (utm_source != ''){
            if (parseSpace) {
                urlT = urlT + '-';
            }
            urlT = urlT +'promo_'+utm_source;
             parseSpace = true;

        }
        if (id != ''){
            if (parseSpace) {
                urlT = urlT + '-';
            }
            urlT = urlT +'id_'+id;
             parseSpace = true;
        }
    }
    return (
<div className="App-header">

    <div className="div">
        <a className = 'btnPromote' href={urlT}>
            <div className="divBtPromote" ></div>
            <p className="txtBtPromote">Telegram bot</p>
        </a>
    </div>
    <div className="div">
        <a className = 'btnPromote' href={'https://t.me/hab_vet'}>
            <div className="divBtPromote" ></div>
            <p className="txtBtPromote">{language === 'en' ? "Write to a specialist" : "Написати фахівцю"}</p>
        </a>
    </div>
</div>  )
}
