
export const anket = (id:string, utm_source:string) =>{

    return (
        <div className="App-header">


            <div className="div">
                <a className = 'btnPromote' href={'https://t.me/cgc_vetbot?start=anket'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Telegram</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'viber://pa?chatURI=cgc_vetbot&context=anket'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Viber</p>
                </a>
            </div>

        </div>  )

}
