import React from "react";

interface AppHeaderENProps {
    utm: string; // Определение типа для параметра utm
}

export const AppHeader: React.FC<AppHeaderENProps> = ({utm}) =>{
    return(
        <div className="App-header">
            <img src="https://bots.hab.vet/hab.jpg" className="image"/>
    <h1 className = 'h1' >Перший ветеринарний хаб</h1>
    <h2 className = 'text'>
        Ми - кейргівери за покликанням | Розуміємо без слів |
        <a href="tel:+380973093309" className = 'text'> 380973093309</a>
    </h2>
            <div className="divLangMain">
            <div className="divLang">
                <a className = 'btnPromoteLang' href={'https://bots.hab.vet/info?utm_source='+utm+'&lang=en'}>
                    <div className="divBtPromote" ></div>
                    <p className="txtBtPromoteBut">en</p>
                </a>
            </div>
            <div className="divLangBut">
                <a className = 'btnPromoteLang'>
                    <div className="divBtPromote" ></div>
                    <p className="txtBtPromoteBut">ua</p>
                </a>
            </div>
        </div>
        </div>
            )
}