


export const viber = (id:string, utm_source:string,language:string) =>{

    let urlV = 'viber://pa?chatURI=cgc_vetbot';
    let parseSpace = false;
    if (utm_source != '' || id !=''){
        urlV = urlV + '&context=';
        if (utm_source != ''){
            if (parseSpace) {
                urlV = urlV + '-';
            }
            urlV = urlV +'promo_'+utm_source;
            parseSpace = true;

        }
        if (id != ''){
            if (parseSpace) {
                urlV = urlV + '-';
            }
            urlV = urlV +'id_'+id;
            parseSpace = true;
        }
    }

    return (
        <div className="App-header">
            <div className="div">
                <a className = 'btnPromote' href={urlV}>
                    <div className="divBtPromote" ></div>
                    <p className="txtBtPromote">Viber bot</p>
                </a>
            </div>

            <div className="div">
                <a className = 'btnPromote' href={'viber://chat?number=%2B380973093309'}>
                    <div className="divBtPromote" ></div>
                    <p className="txtBtPromote">{language === 'en' ? "Write to a specialist" : "Написати фахівцю"}</p>
                </a>
            </div>
        </div>  )
}