
export const promoter = (id:string, utm_source:string) =>{

    return (
        <div className="App-header">

            <div className="div">
                <a className = 'btnPromote' href={'https://bots.hab.vet/?id='+'checkup'+'&utm_source='+utm_source}>
                    <div className="divBtPromote" ></div>
                    <p className="txtBtPromote">Безкоштовний чекап</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://bots.hab.vet/telegram?id='+id+'&utm_source='+utm_source}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Telegram</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://bots.hab.vet/viber?id='+id+'&utm_source='+utm_source}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Viber</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://www.instagram.com/hab_vet/'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Instagram</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://www.hab.vet/?utm_mediun=bots&utm_source='+utm_source}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Сайт</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://goo.gl/maps/GFzPZ5kViLuaEAL58'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Мапа</p>
                </a>
            </div>

        </div>  )

}
