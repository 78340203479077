
export const consumer = (id:string, utm_source:string) =>{

    return (
        <div className="App-header">


            <div className="div">
                <a className = 'btnPromote' href={'https://www.hab.vet/CGCprice.pdf'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Прайс</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://www.hab.vet/public_offer.pdf'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Публічна оферта</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://g.page/r/CTmglNwx9z1VEAg/review'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Залишити відгук</p>
                </a>
            </div>

        </div>  )

}
